import leftPad from 'left-pad';
import moment from 'moment';

export const utils = {
    get10Number() {
        let data = [];
        for (let index = 0; index < 10; index++) {
            data.push(index);
        }

        return data;
    },
    get100Number() {
        let data = [];
        for (let index = 0; index < 100; index++) {
            data.push(leftPad(index, 2, '0'));
        }

        return data;
    },
    get1000Number() {
        let data = [];
        for (let index = 0; index < 1000; index++) {
            data.push(leftPad(index, 3, '0'));
        }
        return data;
    },
    permute(xs: any) {

        let ret = [];

        for (let i = 0; i < xs.length; i = i + 1) {
            let rest: any = utils.permute(xs.slice(0, i).concat(xs.slice(i + 1)));

            if (!rest.length) {
                ret.push([xs[i]])
            } else {
                for (let j = 0; j < rest.length; j = j + 1) {
                    ret.push([xs[i]].concat(rest[j]))
                }
            }
        }

        return ret;
    },
    numberWithCommas(x: string) {
        const value = parseFloat(x).toFixed(2);
        const num = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
    },
    random(num: number, pad: number) {
        const n = num - 1;
        const rand = Math.floor(Math.random() * (n));
        return leftPad(rand, pad, '0');
    },
    getFirstDayOfWeek(d: Date) {
        // clone date object, so we don't mutate it
        const date = new Date(d);
        const day = date.getDay(); // get day of week

        // day of month - day of week (-6 if Sunday), otherwise +1
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);

        return new Date(date.setDate(diff));
    },
    getWeek(status: string) {
        const today = new Date();

        const firstday = this.getFirstDayOfWeek(today);

        // Get the last day of the current week (Sunday)
        const lastday = new Date(firstday);
        lastday.setDate(lastday.getDate() + 6);

        // get current week
        if (status === 'current-week') {
            return {
                startDate: moment(firstday).format("YYYY-MM-DD 00:00:00"),
                endDate: moment(lastday).format("YYYY-MM-DD 23:59:59"),
            }
        }

        // get last week
        return {
            startDate: moment(firstday).add(-7, 'd').format("YYYY-MM-DD 00:00:00"),
            endDate: moment(lastday).add(-7, 'd').format("YYYY-MM-DD 23:59:59"),
        }
    }
};