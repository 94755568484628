import React, { FC } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import { LottoMaster } from '../../types/period.type';

const { Title, Text } = Typography;

type Props = {
    lottoMaster: LottoMaster
}


const LottoMasterCard: FC<Props> = ({ lottoMaster }) => {
    return (
        <a href={lottoMaster.link} target="_blank">
            <div className="lotto-card">
                <div className="header">
                    <img src={process.env.REACT_APP_API_URL + lottoMaster?.pic} style={{ width: 100, height: 80, margin: 'auto' }} alt="line lotto" />
                    <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>หวย{lottoMaster?.name}</Title>
                </div>
                {/* <div className="content-card">
                <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>งวด {periodDate}</Title>
            </div> */}
            </div>
        </a>
    )
}

export default LottoMasterCard;